// third party
import { combineReducers } from 'redux';

// project import
import customizationReducer from './customizationReducer';
import userReducer from "./userReducer";
import contractReducer from "./contractReducer";

// ==============================|| REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  user: userReducer,
  contract: contractReducer
});

export default reducer;
