// action - state management

const initialState = {
  contract: null,
};

let storedState = initialState;

const storedStateJSON = localStorage.getItem('reduxState');

if (storedStateJSON) {
  storedState = JSON.parse(storedStateJSON);
}

const contractReducer = (state = storedState, action) => {
  let newState;

  switch (action.type) {
    case 'SAVE_CONTRACT':
      newState = {
        ...state,
        contract: action.payload.contract
      };

      localStorage.setItem('reduxState', JSON.stringify(newState));

      return newState;
    default:
      return state;
  }
};

export default contractReducer;
