// action - state management

const initialState = {
  user: null,
  storeInfo: null,
  isLoggedIn: false
};

let storedState = initialState;

const storedStateJSON = localStorage.getItem('reduxState');

if (storedStateJSON) {
  storedState = JSON.parse(storedStateJSON);
}

const userReducer = (state = storedState, action) => {

  console.log("ENTROU AQUI USER REDUCER");

  console.log(action);

  let newState;

  switch (action.type) {
    case 'LOGIN':
      newState = {
        ...state,
        user: action.payload.user,
        storeInfo: action.payload.storeInfo,
        isLoggedIn: true
      };

      localStorage.setItem('reduxState', JSON.stringify(newState));

      return newState;
    case 'LOGOUT':

      localStorage.removeItem('reduxState');

      return {
        ...state,
        user: null,
        storeInfo: null,
        isLoggedIn: false
      };
    default:
      return state;
  }
};

export default userReducer;
