import PropTypes from 'prop-types';
import React, {useEffect} from 'react';

import axios from 'axios';

// material-ui
import { useTheme } from '@mui/material/styles';

// project import
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import { drawerWidth } from 'config.js';

// assets
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
//import logo from 'assets/images/logo.svg';

import Alert from '@mui/material/Alert';

import { useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import { formatarValorEmReais } from "../../../views/Utils/format-number";

import {
  Button,
  Modal,
  Box,
  Typography,
  TextField,
  Grid,
  IconButton,
  InputAdornment,
} from '@mui/material';

const Header = ({ drawerToggle }) => {
  const theme = useTheme();

  const contract = useSelector(state => state.contract.contract);

  const [invoice, setInvoice] = React.useState(null);
  const [paymentProof, setPaymentProof] = React.useState(null);
  const [invoiceModal, setOpenInvoiceModal] = React.useState(false);

  const user = useSelector(state => state.user);

  const openInvoiceModal = async () => {
    setOpenInvoiceModal(true);

    await axios.get(`${process.env.REACT_APP_URL_BACKEND}/${user.storeInfo.slug}/admin/general_settings/overdue_invoice`, {
      headers: {
        'Authorization': process.env.REACT_APP_API_AUTHORIZATION,
      }
    }).then(response => {
      setInvoice(response.data);
    })
    .catch(error => {
      console.error('Erro na requisição:', error);
    });
  }

  const closeInvoiceModal = () => setOpenInvoiceModal(false);

  console.log("contrato aqui", contract);


  const handlePayment = async () => {
    event.preventDefault();

    const formData = new FormData();

    formData.append('evidence', paymentProof);
    formData.append('invoice_id', invoice?.id);

    const response = await axios.post(`${process.env.REACT_APP_URL_BACKEND}/${user.storeInfo.slug}/admin/general_settings/pay_invoice`, formData, {
      headers: {
        'Authorization': process.env.REACT_APP_API_AUTHORIZATION,
      }
    });

    if(response && response.data && response.data.id){
      window.location.reload();
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPaymentProof(file);
    }
  };

  return (
    <>
      <Box width={drawerWidth}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Grid item>
              <Box mt={0.0}>
              </Box>
            </Grid>
          </Box>
          <Grid item>
            <IconButton
              edge="start"
              sx={{ mr: theme.spacing(100) }}
              color="inherit"
              aria-label="open drawer"
              onClick={drawerToggle}
              size="large">
              <MenuTwoToneIcon sx={{ fontSize: '2.5rem' }}/>
            </IconButton>
            <span style={{fontSize: "20px", fontWeight: "bold"}}> </span>
          </Grid>
        </Grid>
      </Box>

      {(contract && contract.status == 5 && <Box style={{display: "flex", justifyContent: "center", width: "70%"}}>
        <span>
          <Alert severity="warning">Atenção, sua fatura está vencida! <a style={{cursor: "pointer"}} onClick={openInvoiceModal}><b>Clique aqui</b></a> para realizar o pagamento. </Alert>
        </span>
      </Box>)}

      <Box sx={{ flexGrow: 1 }} />
      <NotificationSection />
      <ProfileSection />

      <Modal
      open={invoiceModal}
      onClose={closeInvoiceModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography id="modal-title" variant="h6" component="h2">
            Pagamento via PIX
          </Typography>
          <IconButton onClick={closeInvoiceModal}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Typography id="modal-description" sx={{ mt: 2 }}>
          Pague a fatura usando o QR Code abaixo ou copie as chaves PIX.
        </Typography>

        {/* QR Code e Chaves PIX */}
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
            <img src={invoice?.qr_code} alt="QR Code Pix" width="200" height="200" />
            <Typography variant="body2" sx={{ mt: 1 }}>
              Escaneie o QR Code para realizar o pagamento
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">Chaves PIX:</Typography>
          </Grid>
        </Grid>

        <TextField
          fullWidth
          label="Valor do Pagamento"
          type="text"
          variant="outlined"
          value={formatarValorEmReais(invoice?.value)}
          disabled={true}
          sx={{ mt: 3 }}
          InputProps={{
            startAdornment: <InputAdornment position="start"></InputAdornment>,
          }}
        />

        <Button
          fullWidth
          variant="outlined"
          component="label"
          startIcon={<FileUploadIcon />}
          sx={{ mt: 3 }}
        >
          Enviar Comprovante
          <input type="file" hidden onChange={handleFileUpload} />
        </Button>

        {/* Mostrando nome do arquivo selecionado */}
        {paymentProof && (
          <Typography variant="body2" sx={{ mt: 1 }}>
            Comprovante: {paymentProof.name}
          </Typography>
        )}

        <Button
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          onClick={handlePayment}
          disabled={!paymentProof} // Desabilitar botão se não tiver valor e comprovante
        >
          Concluir Pagamento
        </Button>
      </Box>
    </Modal>
    </>
  );
};

Header.propTypes = {
  drawerToggle: PropTypes.func
};

export default Header;
