import React from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useSelector, useDispatch } from 'react-redux';
import theme from 'themes';
import Routes from 'routes/index';
import NavigationScroll from './NavigationScroll';

import RoutesAuth from 'routes/RoutesAuth'

import axios from 'axios';

const isAuthenticated = () => {
  const token = localStorage.getItem('adminUserToken');

  if(!token){
    return false;
  }

  return true;
};

function verificarManifestLink() {
  const manifestLink = document.querySelector('link[rel="manifest"]');
  if (manifestLink) {
    return true;
  } else {
    return false;
  }
}

function registerServiceWorker(){
  if (!verificarManifestLink()) {
    const baseUrl = "https://painel.amarko.com.br/";
    const manifestJson = generateManifestJson(baseUrl);

    // Crie um blob com o conteúdo do manifesto
    const blob = new Blob([manifestJson], { type: 'application/json' });
    const manifestUrl = URL.createObjectURL(blob);

    // Adicione o link do manifesto ao HTML
    const link = document.createElement('link');
    link.setAttribute('rel', 'manifest');
    link.setAttribute('href', manifestUrl);
    document.head.appendChild(link);
  }
}

const generateManifestJson = (baseUrl) => {
  const manifest = {
    "name": "Painel AMarko",
    "short_name": "Painel AMarko",
    "start_url": baseUrl,
    "display": "standalone",
    "icons": [
      {
        "src": "https://painel.amarko.com.br/favicon.ico",
        "sizes": "64x64 32x32 24x24 16x16",
        "type": "image/x-icon"
      }
    ]
  };

  // Converta o objeto em JSON
  return JSON.stringify(manifest);
};

const App = () => {
  const customization = useSelector((state) => state.customization);

  const user = useSelector(state => state.user);

  const dispatch = useDispatch();

  React.useEffect(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/${user?.storeInfo?.slug}/admin_panel/contract`, {
        headers: {
          'Authorization': process.env.REACT_APP_API_AUTHORIZATION,
        }
      });

      console.log("RESPONSE, CONTRACT", response);

      if(response && response.data && response.data.contract){
        dispatch({
          type: 'SAVE_CONTRACT',
          payload: {
            contract: response.data.contract
          }
        });

        console.log("window.location.href ", window.location.pathname);

        if(response.data.contract.status == 4 && window.location.pathname != "/blocked" && isAuthenticated()){
          window.location.href = "/blocked";
        }
      }
    }catch(e){
      console.error(e);
    }
  }, []);

  registerServiceWorker();

  return (
    <>
      {isAuthenticated() ? (
        <NavigationScroll>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme(customization)}>
              <CssBaseline />
              <Routes />
            </ThemeProvider>
          </StyledEngineProvider>
        </NavigationScroll>
      ) : (
        <ThemeProvider theme={theme(customization)}>
          <CssBaseline />
          <RoutesAuth />
        </ThemeProvider>
      )}
    </>
  );
};

export default App;
