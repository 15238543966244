// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const MENU_TYPE = '@customization/MENU_TYPE';

export const login = (userData) => ({
  type: 'LOGIN',
  payload: userData
});

export const logout = () => ({
  type: 'LOGOUT'
});
