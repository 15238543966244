export function formatarValorEmReais(valor){
  const valorFormatado = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(valor);

  return valorFormatado;
}

export function converterTempoParaTexto(tempoEmMinutos){
  const horas = Math.floor(tempoEmMinutos / 60);
  const minutos = tempoEmMinutos % 60;

  if (horas === 0) {
    return `${minutos} minuto${minutos !== 1 ? 's' : ''}`;
  } else if (minutos === 0) {
    return `${horas} hora${horas !== 1 ? 's' : ''}`;
  } else {
    return `${horas} hora${horas !== 1 ? 's' : ''} e ${minutos} minuto${minutos !== 1 ? 's' : ''}`;
  }
}

export function PercentageToCurrencyConverter(percentage, total){
  if (!percentage || !total) {
    return 0; // Se a porcentagem ou o total não forem fornecidos, retorna 0
  }
  const percentageValue = (percentage / 100) * total;
  return percentageValue.toFixed(2); // Arredonda para 2 casas decimais
}